<template>
  <el-upload
    ref="upload"
    action="/api/file/upload"
    :limit="limit"
    :accept="accept"
    :before-upload="beforeUpload"
    :on-remove="onRemove"
    :on-success="onSuccess"
    :on-preview="onPreview"
    :on-error="onError"
    data-testid="upload"
    :file-list="files"
  >
    <slot></slot>
  </el-upload>
</template>

<script>
import { allowTypes } from '@/util/urlToFile';
export default {
  name: 'vUpload',
  props: {
    limit: Number,
    accept: {
      type: String,
      default: 'image/png, image/jpeg, image/jpg, image/bmp, .pdf, .doc, .docx'
    },
    maxSize: {
      type: Number,
      default: 5
    },
    allowedFile: {
      type: Array,
      default: function () {
        return ['png', 'jpg', 'jpeg', 'bmp', 'pdf', 'doc', 'docx'];
      }
    },
    files: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      fileList: []
    };
  },
  watch: {
    files() {
      this.fileList = [...this.files, ...this.fileList];
    }
  },
  methods: {
    onPreview(file) {
      // Not uploaded
      if (!file || !file.response || !file.response.data) return;

      let html = '';
      let allow = Object.keys(allowTypes).includes(file.raw.type);
      if (allow) {
        html = `<${allowTypes[file.raw.type]} src="${file.response.data}" class='${allowTypes[file.raw.type]}Ref' />`;
        this.$confirm(html, '', {
          showCancelButton: false,
          showConfirmButton: false,
          dangerouslyUseHTMLString: true,
          center: true
        });
      } else {
        console.log('不支持预览');
        this.$message({ message: this.$t('fileUploader.errorPreview'), type: 'error' });
      }
    },
    updateFileInfo() {
      this.$emit('updateFileInfo', this.fileList);
    },
    beforeUpload(file) {
      if (file.size / 1024 / 1024 > this.maxSize) {
        console.log('file size ' + file.size + ' is over size');
        return false;
      }
      if (!this.allowedFile.some(s => file.name.toLowerCase().endsWith(s))) {
        console.log('unsupported file type: ' + file.name);
        return false;
      }
    },
    onRemove(file) {
      console.log(file, 'onremove');
      if (file.response) {
        this.fileList = this.fileList.filter(item => item.url != file.response.data);
        console.log(this.fileList, ' this.fileList');
        this.updateFileInfo();
      }
    },
    onSuccess(response, file) {
      console.log(response, file, 'filefile');
      switch (response.code) {
        case 0:
          this.fileList.push({ name: file.name, url: response.data });
          this.updateFileInfo();
          break;
        case 'session_timeout':
          return this.$router.push(response.loginUrl);
        case 500:
          return this.removeUnUploadFile(file);
      }
    },
    onError() {
      this.$message({ message: this.$t('responseMsg.500'), type: 'error' });
    },
    removeUnUploadFile(file) {
      const uploadFiles = this.$refs['upload'].uploadFiles;
      const index = uploadFiles.indexOf(file);
      uploadFiles.splice(index, 1);
      this.fileList.splice(index, 1);
      this.updateFileInfo();
      this.$message({ message: this.$t('fileUploader.failedFileExtension'), type: 'error' });
    }
  }
};
</script>
<style lang="scss">
.el-message-box__headerbtn {
  .el-message-box__close {
    color: $main-text;
  }
}
.el-message-box__content {
  max-height: 425px;
  overflow-y: scroll;
  .imgRef {
    width: 100%;
    height: auto;
  }
  .iframeRef {
    width: 100%;
    height: 410px;
  }
}
@media (max-width: 640px) {
  .el-message-box {
    width: 90%;
  }
}
</style>
