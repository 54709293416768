<template>
  <div class="promotion">
    <p class="pageHeader">{{ clientPortalSettings.pageTitle }}</p>
    <div style="margin-left: 30px; margin-right: 30px">
      <el-card shadow="never">
        <div class="grid-container">
          <div class="left">
            <el-image class="mb-3 image-border-radius" :src="clientPortalSettings.document" :fit="'contain'" />
            <div class="text_wrap">
              <div class="text vhtml_component" v-html="clientPortalSettings.description1"></div>
            </div>
          </div>
          <div class="right">
            <div class="bg-gray">
              <div class="meta_box">
                <div class="mb-4">
                  <div class="text vhtml_component" v-html="clientPortalSettings.description2"></div>
                  <a class="text_link" :href="clientPortalSettings.tncLink" target="_blank" data-testid="tnc">
                    {{ $t('promotion.noDepositBonus.tnc') }}<i class="el-icon-arrow-right"></i>
                  </a>
                </div>
                <el-button
                  type="primary"
                  v-if="bonusHistoryData.length > 0"
                  class="cyan_button"
                  data-testid="fund"
                  @click="tradeNow"
                >
                  {{ $t('promotion.noDepositBonus.tradeNow') }}
                </el-button>
              </div>
            </div>
          </div>
        </div></el-card
      >
      <el-card
        shadow="never"
        :body-style="{ padding: '0' }"
        v-if="verificationStatus.poa || verificationStatus.poi || verificationStatus.pof"
      >
        <div class="status-wrapper">
          <div v-for="(value, key) in verificationStatus" :key="key" class="status-box-wrapper">
            <div class="status-box">
              <img
                :src="
                  value.status === 'completed'
                    ? completeStatusIcon
                    : value.status === 'pending'
                    ? pendingStatusIcon
                    : initStatusIcon
                "
                class="status-icon"
              />
              <div v-html="$t(value.label)"></div>
              <el-button
                v-if="
                  (key === 'pof' && value.status === 'init' && !verificationStatus['poi']) ||
                  (verificationStatus['poi'] &&
                    verificationStatus['poi'].status == 'completed' &&
                    key === 'pof' &&
                    value.status === 'init')
                "
                type="primary"
                class="cyan_button"
                @click="openUploadPofDialog"
              >
                {{ $t('promotion.noDepositBonus.verifyNow') }}</el-button
              >
            </div>
            <el-divider direction="vertical" />
          </div>
        </div>
      </el-card>
      <el-tabs v-model="activityTab">
        <el-tab-pane :label="$t('promotion.noDepositBonus.bonusHistory')" name="bonusHistory">
          <el-table :data="bonusHistoryData" class="borderRadius">
            <el-table-column prop="claimDate" :label="$t('promotion.noDepositBonus.claimDate')"> </el-table-column>
            <el-table-column prop="tradingAccount" :label="$t('promotion.noDepositBonus.tradingAccount')">
            </el-table-column>
            <el-table-column prop="credit" :label="$t('promotion.noDepositBonus.credit')"> </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
    </div>
    <UploadPofDialog :uploadPofVisible.sync="uploadPofVisible" :successCallback="getCampaignDetails" />
  </div>
</template>

<script>
import TermsAndConditions from '../../components/promotion/noDepositBonus/TermAndConditions.vue';
import { apiCampaignInfo } from '@/resource';
import initStatusIcon from '@/assets/images/promotion/noDepositBonus/init-icon.svg';
import pendingStatusIcon from '@/assets/images/promotion/noDepositBonus/pending-icon.svg';
import completeStatusIcon from '@/assets/images/promotion/noDepositBonus/complete-icon.svg';
import UploadPofDialog from '../../components/promotion/noDepositBonus/UploadPofDialog.vue';
import moment from 'moment';

export default {
  name: 'dynamicNoDepositBonus',
  components: {
    TermsAndConditions,
    UploadPofDialog
  },
  data() {
    return {
      activityTab: 'bonusHistory',
      bonusHistoryData: [],
      uploadPofVisible: false,
      verificationStatus: {},
      clientPortalSettings: {}
    };
  },
  computed: {
    campaignID() {
      return this.$route.params.campaignId;
    },
    lang() {
      return this.$store.state.common.lang;
    }
  },
  mounted() {
    this.getCampaignDetails();
  },
  created() {
    this.initStatusIcon = initStatusIcon;
    this.pendingStatusIcon = pendingStatusIcon;
    this.completeStatusIcon = completeStatusIcon;
  },
  methods: {
    tradeNow() {
      this.$router.push('/liveAccount');
    },
    openUploadPofDialog() {
      this.uploadPofVisible = true;
    },
    setVerificationStatus(key, checkEnable, auditStatus, label) {
      if (checkEnable) {
        this.verificationStatus[key] = {
          status: (function (number) {
            switch (number) {
              case 0:
              case 1:
              case 4:
                return 'pending';
              case -1:
              case 3:
              case null:
                return 'init';
              default:
                return 'completed';
            }
          })(auditStatus),
          label: label
        };
      }
    },
    async getCampaignDetails() {
      await apiCampaignInfo(this.campaignID, this.lang)
        .then(resp => {
          if (resp.data.code === 0) {
            const data = resp.data.data;
            const tableData = data.noDepositBonusV2Claimed;
            const tempCpSetting = data.clientPortalSetting;
            this.setVerificationStatus(
              'poi',
              data.poiCheckEnable,
              data.poiAuditStatus,
              'promotion.noDepositBonus.IDVerification'
            );
            this.setVerificationStatus(
              'poa',
              data.poaCheckEnable,
              data.poaAuditStatus,
              'promotion.noDepositBonus.POAVerification'
            );
            this.setVerificationStatus(
              'pof',
              data.pofCheckEnable,
              data.pofAuditStatus,
              'promotion.noDepositBonus.faceVerification'
            );
            if (tableData) {
              this.bonusHistoryData = [
                {
                  claimDate: moment(tableData.claimDate).format('DD-MM-YYYY'),
                  tradingAccount: tableData.mt4Account,
                  credit: tableData.amount + ' ' + tableData.currency
                }
              ];
            }

            this.clientPortalSettings = {
              ...tempCpSetting,
              document: tempCpSetting.document ? `${window.location.origin}/api${tempCpSetting.document}` : ''
            };
            this.$store.commit('promotion/setClientPortalLang', this.clientPortalSettings.languageCode);
          }
        })
        .catch(e => {
          console.log(e);
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.el-table__empty-block {
  background-color: white !important;
}
.pageHeader {
  font-size: 24px;
  font-weight: bold;
  margin-left: 50px;
  margin-top: 36px;
}
.promotion {
  .el-card {
    margin-bottom: 24px;
    /deep/ .el-card__body {
      @media (max-width: 1024px) {
        padding: 0;
      }
    }
    border-radius: 20px;
  }
  .grid-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    @media (max-width: 1024px) {
      margin-bottom: 1rem;
    }

    .left,
    .right {
      .image-border-radius {
        border-radius: 8px;
        @media (max-width: 1024px) {
          border-radius: 0;
        }
      }
      box-sizing: border-box;
      flex: 1 1 calc(50% - 15px);
      @media (max-width: 1024px) {
        flex: 1 1 100%;
      }
      .text_wrap {
        @media (max-width: 1024px) {
          padding: 0px 16px;
        }
      }
      /deep/ .text {
        font-size: 16px;
        margin-bottom: 1em;
        line-height: 1.4;
      }
    }
    .left {
      @include rtl-sass-prop(margin-right, margin-left, 30px);
      @media (max-width: 1024px) {
        margin: 0 !important;
      }
    }
    .right {
      @media (max-width: 1024px) {
        padding: 8px;
      }
    }
  }
  .bg-gray {
    padding: 16px;
    border-radius: 8px;
  }
  /deep/ .el-dialog__header {
    font-size: 22px;
    font-weight: 700;
    text-align: left !important;
    .el-dialog__headerbtn {
      font-size: 22px;
    }
  }
  /deep/ .table_box {
    @media (max-width: 1024px) {
      padding: 30px 0;
      h4 {
        padding-left: 16px;
      }
    }
  }
  /deep/ .el-table {
    background-color: white;
    tr th .cell,
    tr td .cell {
      text-align: center;
    }
  }
  /deep/ .el-icon-arrow-left,
  /deep/ .el-icon-arrow-right {
    transform: none;
  }
  .el-table tr th {
    background-color: white;
  }
}
.cyan_button {
  font-weight: bold;
  padding: 15px 15px;
  background-color: #006bff;
}

.borderRadius {
  border-radius: 20px;
}

.status-wrapper {
  min-height: 72px;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .status-box-wrapper {
    flex: 1;
    display: flex;
    align-items: stretch;
    justify-content: space-between;

    &:last-child {
      .el-divider {
        visibility: hidden;
      }
    }

    .el-divider {
      flex: none;
      height: auto;
      margin: 20px;
      background-color: #e3e4e5;
      @media (max-width: 1024px) {
        display: none;
      }
    }

    .status-box {
      flex: 1;
      padding: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 12px;
      text-align: center;
      text-transform: capitalize;
      @media (max-width: 1024px) {
        height: 72px;
        padding: 0 24px;
      }

      .status-icon {
        width: 28px;
        height: 28px;
        object-fit: scale-down;
        object-position: center;
        margin-top: 6px;
      }
    }
  }
}
</style>
