<template>
  <div>
    <el-dialog
      :title="$t('promotion.noDepositBonus.faceVerification')"
      :visible.sync="visible"
      @close="closeUploadPofDialog"
      custom-class="upload-pof-dialog"
    >
      <el-form :model="form" :rules="rules" ref="ruleForm">
        <div class="pof-info-container">
          <img class="pof-info-img" src="@/assets/images/promotion/noDepositBonus/faceVerification/sample.svg" />
          <p class="pof-info-desc">{{ $t('promotion.noDepositBonus.pofDesc') }}</p>
        </div>
        <el-form-item prop="pofFilePathList">
          <div class="upload_box" style="border: '1px solid blue'">
            <vUploadNew ref="vUploadNew" :limit="1" :allowedFile="pofAllowedFiles" @updateFileInfo="updatePofInfo">
              <div>{{ $t('register.btn.upload') }}</div>
            </vUploadNew>
          </div>
        </el-form-item>
        <ul class="upload_tip">
          <li>
            <span>{{ $t('promotion.noDepositBonus.poftit1') }}</span>
          </li>
          <li>
            <span> {{ $t('fileUploader.supportedFormatCustom', { files: pofAllowedFiles.join(', ') }) }}</span>
          </li>
          <li>
            <span>{{ $t('fileUploader.maxSize', { maxSize: 5 }) }}</span>
          </li>
        </ul>
        <div class="sample-wrapper">
          <div v-for="sample in sampleImages" :key="sample.label" class="sample-container">
            <img class="pof-info-img" :src="sample.src" />
            <div>{{ $t(sample.label) }}</div>
          </div>
        </div>
      </el-form>
      <div slot="footer">
        <div class="dialog-footer">
          <el-button :loading="submitPofLoading" type="primary" @click="submitPofForm" data-testid="submit">
            {{ $t('common.button.submit') }}
          </el-button>
        </div>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="successDialogVisible" @close="closeSuccessDialog" custom-class="success-dialog">
      <div class="success-wrapper">
        <img class="review-icon" src="@/assets/images/promotion/noDepositBonus/review-icon.svg" />
        <div class="title">{{ $t('promotion.noDepositBonus.faceVerificationReview') }}</div>
        <div>{{ $t('promotion.noDepositBonus.faceVerificationReviewDesc') }}</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { apiVerificationV2 } from '@/resource';
import vUploadNew from '@/components/vUploadNew.vue';
import blurrySampleImage from '@/assets/images/promotion/noDepositBonus/faceVerification/blurry.png';
import edgeMissingSampleImage from '@/assets/images/promotion/noDepositBonus/faceVerification/edgeMissing.png';
import reflectiveSampleImage from '@/assets/images/promotion/noDepositBonus/faceVerification/reflective.png';
import goodSampleImage from '@/assets/images/promotion/noDepositBonus/faceVerification/good.png';

export default {
  name: 'UploadPofDialog',
  components: { vUploadNew },
  props: {
    uploadPofVisible: { type: Boolean, required: true },
    successCallback: { type: [Function, null], dafault: null }
  },
  data() {
    return {
      visible: false,
      successDialogVisible: false,
      form: {
        pofFilePathList: []
      },
      rules: {
        pofFilePathList: [
          {
            type: 'array',
            required: true,
            message: this.$t('promotion.noDepositBonus.filetip'),
            trigger: 'change'
          }
        ]
      },
      submitPofLoading: false
    };
  },
  watch: {
    uploadPofVisible(bool) {
      this.visible = bool;
    },
    visible(bool) {
      if (!bool) {
        if (this.$refs.vUploadNew && this.$refs.vUploadNew.$refs.upload) {
          this.$refs.vUploadNew.$refs.upload.clearFiles();
        }

        this.$refs['ruleForm'].resetFields();
      }
      this.$emit('update:uploadPofVisible', bool);
    }
  },
  created() {
    this.sampleImages = [
      { src: blurrySampleImage, label: 'promotion.noDepositBonus.uploadTemplate.blurry' },
      { src: edgeMissingSampleImage, label: 'promotion.noDepositBonus.uploadTemplate.edgeMissing' },
      { src: reflectiveSampleImage, label: 'promotion.noDepositBonus.uploadTemplate.reflective' },
      { src: goodSampleImage, label: 'promotion.noDepositBonus.uploadTemplate.good' }
    ];
    this.pofAllowedFiles = ['png', 'jpg', 'jpeg', 'bmp', 'pdf', 'doc', 'docx'];
  },
  methods: {
    closeUploadPofDialog() {
      this.visible = false;
    },
    updatePofInfo(fileList = []) {
      const arr = fileList.map(i => i.url);
      this.form.pofFilePathList = arr;
      if (fileList.length) this.$refs['ruleForm'].clearValidate('pofFilePathList');
    },
    async submitPofForm() {
      this.submitPofLoading = true;
      this.$refs['ruleForm'].validate(async valid => {
        if (valid) {
          const isSuccess = await this.submitPof();
          if (isSuccess) {
            this.closeUploadPofDialog();
            this.successDialogVisible = true;
            if (this.successCallback) {
              await this.successCallback();
            }
          }
        }
        this.submitPofLoading = false;
      });
    },
    async submitPof() {
      try {
        const res = await apiVerificationV2({
          filePathList: this.form.pofFilePathList,
          fileType: 27,
          auditType: 1
        });

        return res && res.data.code == 0;
      } catch (error) {
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.errmsg &&
          error.response.data.errmsg.length > 0
        ) {
          this.$message({ message: error.response.data.errmsg, type: 'error' });
        }
        return false;
      }
    },
    async closeSuccessDialog() {
      this.successDialogVisible = false;
    }
  }
};
</script>

<style lang="scss" scoped>
/deep/ .el-dialog {
  margin-top: 40px !important;
}

.upload-pof-dialog {
  .el-form {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    gap: 20px;

    .el-form-item {
      margin: 0;
    }

    .pof-info-img {
      width: 110px;
      height: 74px;
    }

    .pof-info-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 20px;

      .pof-info-desc {
        margin: 22px 0;
        line-height: 180%;
      }
    }

    .upload_tip {
      font-size: 12px;
      line-height: 2;
      li {
        list-style: '* ';
        @include rtl-sass-prop(margin-left, margin-right, 10px);
        // color: $orange-2;
        // span {
        // color: $secondary-text;
        // }
      }
    }

    .sample-wrapper {
      display: flex;
      align-items: stretch;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 10px;

      .sample-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        color: #000;
        gap: 8px;
      }
    }
  }

  .dialog-footer {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    .el-button {
      min-width: 200px;
      font-weight: bold;
      padding: 15px 15px;
      background-color: #006bff;
    }
  }
}

.success-dialog {
  /deep/ &.el-dialog {
    max-width: 384px;
  }
  .success-wrapper {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    text-align: center;
    .review-icon {
      width: 72px;
      height: 72px;
    }
    .title {
      color: #1d1d1f;
      font-size: 16px;
      font-weight: 700;
    }
  }
}

.upload_box {
  /deep/ {
    .el-upload-list {
      > li {
        display: inline-block;
        width: 50%;
      }
    }
    .el-icon-close-tip {
      display: none;
    }
    .el-icon-upload-success {
      color: #4da410;
    }
    .el-form-item__content {
      margin-left: 0 !important;
      > div {
        text-align: center;
      }
    }

    .el-upload {
      border-radius: $border-radius;
      width: 100%;
      min-height: 40px;
      border: 1px solid #006bff;
      // color: $orange-2;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;

      > div::before {
        content: '';
        display: inline-block;
        width: 16px;
        height: 16px;
        background: url(~@/assets/images/register/upload2.png);
        background-size: 100% 100%;
        position: relative;
        top: 2px;
        right: 4px;
      }
    }
  }
}
</style>
<style>
.el-dialog__body {
  word-break: break-word !important;
}
</style>
