var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"promotion"},[_c('p',{staticClass:"pageHeader"},[_vm._v(_vm._s(_vm.clientPortalSettings.pageTitle))]),_c('div',{staticStyle:{"margin-left":"30px","margin-right":"30px"}},[_c('el-card',{attrs:{"shadow":"never"}},[_c('div',{staticClass:"grid-container"},[_c('div',{staticClass:"left"},[_c('el-image',{staticClass:"mb-3 image-border-radius",attrs:{"src":_vm.clientPortalSettings.document,"fit":'contain'}}),_c('div',{staticClass:"text_wrap"},[_c('div',{staticClass:"text vhtml_component",domProps:{"innerHTML":_vm._s(_vm.clientPortalSettings.description1)}})])],1),_c('div',{staticClass:"right"},[_c('div',{staticClass:"bg-gray"},[_c('div',{staticClass:"meta_box"},[_c('div',{staticClass:"mb-4"},[_c('div',{staticClass:"text vhtml_component",domProps:{"innerHTML":_vm._s(_vm.clientPortalSettings.description2)}}),_c('a',{staticClass:"text_link",attrs:{"href":_vm.clientPortalSettings.tncLink,"target":"_blank","data-testid":"tnc"}},[_vm._v(" "+_vm._s(_vm.$t('promotion.noDepositBonus.tnc'))),_c('i',{staticClass:"el-icon-arrow-right"})])]),(_vm.bonusHistoryData.length > 0)?_c('el-button',{staticClass:"cyan_button",attrs:{"type":"primary","data-testid":"fund"},on:{"click":_vm.tradeNow}},[_vm._v(" "+_vm._s(_vm.$t('promotion.noDepositBonus.tradeNow'))+" ")]):_vm._e()],1)])])])]),(_vm.verificationStatus.poa || _vm.verificationStatus.poi || _vm.verificationStatus.pof)?_c('el-card',{attrs:{"shadow":"never","body-style":{ padding: '0' }}},[_c('div',{staticClass:"status-wrapper"},_vm._l((_vm.verificationStatus),function(value,key){return _c('div',{key:key,staticClass:"status-box-wrapper"},[_c('div',{staticClass:"status-box"},[_c('img',{staticClass:"status-icon",attrs:{"src":value.status === 'completed'
                  ? _vm.completeStatusIcon
                  : value.status === 'pending'
                  ? _vm.pendingStatusIcon
                  : _vm.initStatusIcon}}),_c('div',{domProps:{"innerHTML":_vm._s(_vm.$t(value.label))}}),(
                (key === 'pof' && value.status === 'init' && !_vm.verificationStatus['poi']) ||
                (_vm.verificationStatus['poi'] &&
                  _vm.verificationStatus['poi'].status == 'completed' &&
                  key === 'pof' &&
                  value.status === 'init')
              )?_c('el-button',{staticClass:"cyan_button",attrs:{"type":"primary"},on:{"click":_vm.openUploadPofDialog}},[_vm._v(" "+_vm._s(_vm.$t('promotion.noDepositBonus.verifyNow')))]):_vm._e()],1),_c('el-divider',{attrs:{"direction":"vertical"}})],1)}),0)]):_vm._e(),_c('el-tabs',{model:{value:(_vm.activityTab),callback:function ($$v) {_vm.activityTab=$$v},expression:"activityTab"}},[_c('el-tab-pane',{attrs:{"label":_vm.$t('promotion.noDepositBonus.bonusHistory'),"name":"bonusHistory"}},[_c('el-table',{staticClass:"borderRadius",attrs:{"data":_vm.bonusHistoryData}},[_c('el-table-column',{attrs:{"prop":"claimDate","label":_vm.$t('promotion.noDepositBonus.claimDate')}}),_c('el-table-column',{attrs:{"prop":"tradingAccount","label":_vm.$t('promotion.noDepositBonus.tradingAccount')}}),_c('el-table-column',{attrs:{"prop":"credit","label":_vm.$t('promotion.noDepositBonus.credit')}})],1)],1)],1)],1),_c('UploadPofDialog',{attrs:{"uploadPofVisible":_vm.uploadPofVisible,"successCallback":_vm.getCampaignDetails},on:{"update:uploadPofVisible":function($event){_vm.uploadPofVisible=$event},"update:upload-pof-visible":function($event){_vm.uploadPofVisible=$event}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }